import React, { Suspense, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber"; // Correctly import useFrame from @react-three/fiber
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

const Robot = () => {
  const Robot = useGLTF("./robot/scene.gltf");
  const RobotRef = useRef();

  // Add rotation animation
  useFrame(() => {
    if (RobotRef.current) {
      RobotRef.current.rotation.y += 0.002; // Rotate the Robot slowly
    }
  });

  return (
    <primitive
      ref={RobotRef}
      object={Robot.scene}
      scale={3}
      position-y={-2.6}
      rotation-y={0}
    />
  );
};

const RobotCanvas = () => {
  return (
    <Canvas
      shadows
      frameloop="demand"
      dpr={[1, 2]}
      gl={{ preserveDrawingBuffer: true }}
      camera={{
        fov: 45,
        near: 0.1,
        far: 200,
        position: [-4, 3, 6],
      }}
    >
      <Suspense fallback={null}>
        {/* Orbit Controls to allow user interaction */}
        <OrbitControls
          autoRotate
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
          autoRotateSpeed={0.5} // Control the rotation speed
        />
        {/* Lighting Setup */}
        <ambientLight intensity={1} /> {/* Soft ambient light */}
        <pointLight
          position={[10, 10, 10]}
          intensity={1}
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
        />
        <directionalLight
          position={[-5, 5, 0]}
          intensity={1}
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
        />
        <Robot />
        <Preload all />
      </Suspense>
    </Canvas>
  );
};

export default RobotCanvas;
